<template>
  <v-layout column>
    <v-layout wrap justify-space-between align-center class="mt-2">
      <v-layout wrap align-center>
        <span class="grey--text text--darken-2 headline pr-4">{{ $t('accounts.balance') }}</span>
        <v-tooltip style="flex: none" class="pl-4" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="showCurrenciesWithBalance"
                class="pt-0 mt-0 flex-none"
                color="primary"
                hide-details
                label="0,00"
                compact
                dense
                small
              />
            </div>
          </template>
          <div>{{$t('guests.show_currencies_with_balance')}}</div>
        </v-tooltip>
      </v-layout>
      <v-layout align-center justify-end>
        <tooltip-button
          :text="$t('accounts.actions.top_up_balance')"
          :disabled="isGuestDisabled(guests.read) || isHotelGuest(guests.read) || noShift"
          :disabledTooltip="!(isGuestDisabled(guests.read) || isHotelGuest(guests.read) || noShift)"
          :tooltipText="isGuestDisabled(guests.read)
            ? $t('guests.visitor_disabled')
              : noShift
                ? $t('shifts.warnings.required')
                : $t('guests.disabled_topup_balance_hotel')"
          @click="triggerOpen = new Date()"
          color="primary"
          outlined
        />
        <tooltip-button
          :text="$t('bookings.purchase_packages')"
          :disabled="isGuestDisabled(guests.read) || !guests.read.accountId"
          :tooltipText="isGuestDisabled(guests.read)
            ? $t('guests.visitor_disabled')
            : $t('guests.account_required')"
          @click="goToPackageOrder(guests.read)"
          color="primary"
          buttonClass="ml-2"
          outlined
        />
      </v-layout>
    </v-layout>
    <item-list
      :listId="table"
      :list="guestCurrencies"
      :availableHeaders="headers"
      class="mt-2"
    />
    <add-balance-modal
      :triggerOpen="triggerOpen"
      :guest="guests.read"
    />
  </v-layout>
</template>

<script>
import { isGuestDisabled, isHotelGuest } from '@/utils/GuestUtils.js'
import { mapState, mapGetters } from 'vuex'
import routeNames from '@/router/routes'
import shiftMixins from '@/mixins/Shifts'
export default {
  props: {
    accountCurrencies: Array,
  },
  data () {
    return {
      triggerOpen: null,
      showCurrenciesWithBalance: false,
      table: 'AccountBalance',
      isGuestDisabled,
      isHotelGuest,
      headers: [
        { text: this.$t('fields.amount'), path: 'balance', show: true, getValue: (item) => this.getAccountCurrencyAmount(item) },
        { text: this.$t('packages.name'), path: 'currency.name', show: true }
      ]
    }
  },
  computed: {
    ...mapState(['guests']),
    ...mapGetters({
      isCurrencyLegal: 'configuration/isCurrencyLegal',
      getPackage: 'packages/getPackage'
    }),
    guestCurrencies () {
      const displayedCurrencies = this.accountCurrencies
        .filter(aC => {
          return (
            this.showCurrenciesWithBalance
              ? true
              : !!Math.abs(aC.balance)
            ) && (
              this.isCurrencyLegal(aC.currency.id) ||
              this.getPackage({ currencyId: aC.currency.id})
            )
        })
        .sort((a, b) => b.balance - a.balance)
      const legalCurrencies = displayedCurrencies.filter(aC => this.isCurrencyLegal(aC.currency.id))
      const packageCurrencies = displayedCurrencies.filter(aC => !legalCurrencies.includes(aC))
      return [...legalCurrencies, ...packageCurrencies]
    }
  },
  methods: {
    getAccountCurrencyAmount (accountCurrency) {
      const pkg = this.getPackage({ currencyId: accountCurrency.currency.id })
      return pkg
        ? parseInt(accountCurrency.balance / (pkg.tokenAmount || 1))
        : accountCurrency.balance
    },
    goToPackageOrder () {
      this.$store.commit('packageOrder/setGuestId', this.$route.params.id)
      this.$router.push({ name: routeNames.package_order.name })
    }
  },
  components: {
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    AddBalanceModal: () => import('@/components/view-components/modals/AddBalanceModal.vue'),
    ItemList: () => import('@/components/app-components/listing/ItemList.vue')
  },
  mixins: [
    shiftMixins
  ]
}
</script>
