import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      openShiftModal: null,
      closeShiftModal: null,
      closingShiftId: null,
      openingShift: false,
      closingShift: false,
      openSupervisorModal: null,
      externalPrinterPeripheral: null,
      confirmCloseShift: null
    }
  },
  computed: {
    ...mapGetters({
      isSupervisor: 'shifts/isSupervisor',
      operatorShift: 'shifts/operatorShift',
      printerShift: 'shifts/printerShift'
    }),
    ...mapState({
      shifts: 'shifts',
      selectedPrinter: state => state.peripherals.selectedPrinter,
      selectedStationId: state => state.settings.selectedStationId      
    }),
    notConfigured () {
      return !this.selectedStationId || !this.selectedPrinter
    },
    noShift () {
      return !this.operatorShift || !this.printerShift
    },
    unmatchingShifts () {
      if (this.operatorShift && this.printerShift && this.operatorShift.id === this.printerShift.id) {
        return false
      } else {
        return true
      }
    },
    externalPrinterName () {
      return this.externalPrinterPeripheral ? (this.externalPrinterPeripheral.label || this.externalPrinterPeripheral.model || this.externalPrinterPeripheral.id) : this.$t('shifts.unknown_printer')
    }
  },
  watch: {
    'shifts.finishedLoadingShifts' () {
      if (this.shifts.finishedLoadingShifts) {
        this.getExternalShiftPrinterName()
      }
    }
  },
  methods: {
    openShift () {
      if (!this.isSupervisor) {
        this.openingShift = true
        this.openSupervisorModal = new Date()
      } else {
        this.openShiftModal = new Date()
      }
    },
    closeShift (shiftId) {
      this.closingShiftId = shiftId
      this.closingShift = true
      if (!this.isSupervisor) {
        this.openSupervisorModal = new Date()
      } else {
        this.confirmCloseShift = new Date()
      }
    },
    continueProcess () {
      if (this.openingShift) {
        this.openingShift = false
        this.openShiftModal = new Date()
      } else if (this.closingShift) {
        this.closingShift = false
        this.closeShiftModal = new Date()
      }
    },
    getExternalShiftPrinterName () {
      if (!this.operatorShift) return
      if ((!this.printerShift && this.operatorShift) ||
        (this.printerShift && !this.operatorShift.lastShift) ||
        (this.printerShift && this.operatorShift.lastShift && this.printerShift.id !== this.operatorShift.lastShift.id)) {
        this.$store.dispatch('peripherals/get', { id: this.selectedPrinter }).then((peripheral) => {
          this.externalPrinterPeripheral = peripheral
        })
      }
    }
  }
}
