var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mt-2",attrs:{"wrap":"","justify-space-between":"","align-center":""}},[_c('v-layout',{attrs:{"wrap":"","align-center":""}},[_c('span',{staticClass:"grey--text text--darken-2 headline pr-4"},[_vm._v(_vm._s(_vm.$t('accounts.balance')))]),_c('v-tooltip',{staticClass:"pl-4",staticStyle:{"flex":"none"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-switch',{staticClass:"pt-0 mt-0 flex-none",attrs:{"color":"primary","hide-details":"","label":"0,00","compact":"","dense":"","small":""},model:{value:(_vm.showCurrenciesWithBalance),callback:function ($$v) {_vm.showCurrenciesWithBalance=$$v},expression:"showCurrenciesWithBalance"}})],1)]}}])},[_c('div',[_vm._v(_vm._s(_vm.$t('guests.show_currencies_with_balance')))])])],1),_c('v-layout',{attrs:{"align-center":"","justify-end":""}},[_c('tooltip-button',{attrs:{"text":_vm.$t('accounts.actions.top_up_balance'),"disabled":_vm.isGuestDisabled(_vm.guests.read) || _vm.isHotelGuest(_vm.guests.read) || _vm.noShift,"disabledTooltip":!(_vm.isGuestDisabled(_vm.guests.read) || _vm.isHotelGuest(_vm.guests.read) || _vm.noShift),"tooltipText":_vm.isGuestDisabled(_vm.guests.read)
          ? _vm.$t('guests.visitor_disabled')
            : _vm.noShift
              ? _vm.$t('shifts.warnings.required')
              : _vm.$t('guests.disabled_topup_balance_hotel'),"color":"primary","outlined":""},on:{"click":function($event){_vm.triggerOpen = new Date()}}}),_c('tooltip-button',{attrs:{"text":_vm.$t('bookings.purchase_packages'),"disabled":_vm.isGuestDisabled(_vm.guests.read) || !_vm.guests.read.accountId,"tooltipText":_vm.isGuestDisabled(_vm.guests.read)
          ? _vm.$t('guests.visitor_disabled')
          : _vm.$t('guests.account_required'),"color":"primary","buttonClass":"ml-2","outlined":""},on:{"click":function($event){return _vm.goToPackageOrder(_vm.guests.read)}}})],1)],1),_c('item-list',{staticClass:"mt-2",attrs:{"listId":_vm.table,"list":_vm.guestCurrencies,"availableHeaders":_vm.headers}}),_c('add-balance-modal',{attrs:{"triggerOpen":_vm.triggerOpen,"guest":_vm.guests.read}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }